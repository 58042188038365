import React, { useMemo } from "react";
import {
  Card,
  Col,
  Divider,
  Flex,
  Row,
  Skeleton,
  theme,
  Typography,
} from "antd";
import { TransactionData } from "types/RT";
import { useTranslation } from "react-i18next";
import Status from "./Status";
import dayjs from "dayjs";
import { useSchedules } from "hooks/RT";
import { useMediaQuery } from "hooks/app";

const translationTerms = {
  indexTitle: "recurring_transactions.title",
  title: "recurring_transactions.show.title",
  backBtn: "recurring_transactions.show.back",
  info: {
    reference: "recurring_transactions.show.reference",
    ar_name: "recurring_transactions.show.arabic_name",
    en_name: "recurring_transactions.show.english_name",
    description: "recurring_transactions.show.description",
    status: "recurring_transactions.show.status",
    user_comments: "recurring_transactions.show.additional_comments",
  },
  statuses: {
    active: "recurring_transactions.show.statuses.active",
    draft: "recurring_transactions.show.statuses.draft",
    inactive: "recurring_transactions.show.statuses.inactive",
    scheduled: "recurring_transactions.show.statuses.scheduled",
    failed: "recurring_transactions.show.statuses.failed",
    completed: "recurring_transactions.show.statuses.completed",
  },
  recurringOpt: {
    cardTitle: "recurring_transactions.show.cardTitle",
    frequencyType: "recurring_transactions.show.frequency_type",
    ends: "recurring_transactions.show.ends",
    afterMaximumRuns: "recurring_transactions.show.after_maximum_runs",
    onCustomDate: "recurring_transactions.show.on_custom_date",
    dateFirstRun: "recurring_transactions.show.date_first_run",
    dateNextRun: "recurring_transactions.show.date_next_run",
    dateLastRun: "recurring_transactions.show.date_last_run",
    remainingRuns: "recurring_transactions.show.remaining_runs",
  },
  RTtype: {
    daily: "recurring_transactions.show.daily",
    weekly: "recurring_transactions.show.weekly",
    monthly: "recurring_transactions.show.monthly",
    quarterly: "recurring_transactions.show.quarterly",
    semi_annually: "recurring_transactions.show.semi_annually",
  },
};

// Main Component
export default function RTCardsInfo({
  isLoading,
  RTInfo,
}: {
  isLoading: boolean;
  RTInfo: TransactionData | undefined;
}) {
  const { token } = theme.useToken();

  return (
    <Row gutter={[token.margin, token.marginXL]} style={{ minHeight: "30vh" }}>
      <Col span={24} xl={{ span: 12 }}>
        <GeneralInfoCard RTInfo={RTInfo} isLoading={isLoading} />
      </Col>
      <Col span={24} xl={{ span: 12 }}>
        <RTOptCard RTInfo={RTInfo} isLoading={isLoading} />
      </Col>
    </Row>
  );
}

const GeneralInfoCard = ({
  isLoading,
  RTInfo,
}: {
  isLoading: boolean;
  RTInfo: TransactionData | undefined;
}) => {
  const { token } = theme.useToken();

  const { t } = useTranslation();

  return (
    <Card bordered style={{ height: "100%" }}>
      <Skeleton paragraph={{ rows: 6 }} loading={isLoading}>
        {RTInfo && (
          <Row gutter={[0, token.marginSM]}>
            {Object.entries(RTInfo).map(([key, value]) => {
              if (
                !translationTerms.info[
                  key as keyof typeof translationTerms.info
                ]
              )
                return null;

              return (
                <React.Fragment key={key}>
                  <Col span={12}>
                    <Typography.Text type="secondary">
                      {t(
                        translationTerms.info[
                          key as keyof typeof translationTerms.info
                        ]
                      )}
                    </Typography.Text>
                  </Col>

                  <Col span={12}>
                    {key === "status" ? (
                      <Status status={value} />
                    ) : (
                      <Typography.Text>{value}</Typography.Text>
                    )}
                  </Col>

                  {key === "status" && value === "failed" && (
                    <FailureReason id={RTInfo?.id} />
                  )}
                </React.Fragment>
              );
            })}
          </Row>
        )}
      </Skeleton>
    </Card>
  );
};

const FailureReason = ({ id }: { id: number | undefined }) => {
  const { passedSchedulesQuery } = useSchedules(id, {
    passed: {
      page: "1",
      per_page: "1",
    },
    upcoming: { enabled: false },
  });

  const { t } = useTranslation();

  return (
    <>
      <Col span={12}>
        <Typography.Text type="secondary">
          {t("recurring_transactions.show.failure_reason")}
        </Typography.Text>
      </Col>
      <Col span={12}>
        <Skeleton
          loading={passedSchedulesQuery.isLoading}
          active
          title={false}
          paragraph={{ rows: 1 }}
        >
          <Typography.Text>
            {passedSchedulesQuery.data?.schedules[0]?.failure_reason}
          </Typography.Text>
        </Skeleton>
      </Col>
    </>
  );
};

const RTOptCard = ({
  isLoading,
  RTInfo,
}: {
  isLoading: boolean;
  RTInfo: TransactionData | undefined;
}) => {
  const { t } = useTranslation();

  const dateOpt = useMemo(() => {
    return (
      RTInfo && [
        ["dateNextRun", RTInfo.next_run_date || ""],
        ["dateLastRun", RTInfo.last_run_date || ""],
        ["dateFirstRun", RTInfo.start_date || ""],
      ]
    );
  }, [RTInfo]);

  return (
    <Card
      bordered
      title={t(translationTerms.recurringOpt.cardTitle)}
      type="inner"
      style={{ height: "100%" }}
    >
      <Skeleton paragraph={{ rows: 6 }} loading={isLoading}>
        {RTInfo && (
          <div style={{ width: "95%", marginInline: "auto" }}>
            <RTDateOpt dateOpt={dateOpt} />
            <Divider />
            <RTRepeatOpt RTInfo={RTInfo} />
          </div>
        )}
      </Skeleton>
    </Card>
  );
};

const RTDateOpt = ({ dateOpt }: { dateOpt: string[][] | undefined }) => {
  const { token } = theme.useToken();

  const { t } = useTranslation();

  const isLargeScreen = useMediaQuery("lg:(size>=992)");

  return (
    <Flex vertical={!isLargeScreen} justify="space-between">
      {dateOpt?.map(([key, value], i) => {
        return (
          <Flex
            key={key}
            justify={isLargeScreen ? "start" : "space-between"}
            vertical={isLargeScreen}
            gap={token.marginXS}
          >
            <Typography.Text type="secondary">
              {t(
                translationTerms.recurringOpt[
                  key as keyof typeof translationTerms.recurringOpt
                ]
              )}
            </Typography.Text>
            <Typography.Text>
              {value && dayjs(value).format("YYYY-MM-DD")}
            </Typography.Text>
          </Flex>
        );
      })}
    </Flex>
  );
};

const RTRepeatOpt = ({ RTInfo }: { RTInfo: TransactionData }) => {
  const { token } = theme.useToken();

  const { t } = useTranslation();

  const isSmallScreen = useMediaQuery("sm:(size<=576)");

  return (
    <Flex gap={token.margin} vertical={isSmallScreen} justify="space-between">
      <Flex flex={"0 0 45%"} vertical gap={token.margin}>
        <Flex justify="space-between" flex={1}>
          <Typography.Text type="secondary">
            {t(translationTerms.recurringOpt.frequencyType)}
          </Typography.Text>
          <Typography.Text>
            {t(translationTerms.RTtype[RTInfo.repeat_term])}
          </Typography.Text>
        </Flex>

        <Flex justify="space-between" flex={1}>
          <Typography.Text type="secondary">
            {t(translationTerms.recurringOpt.remainingRuns)}
          </Typography.Text>
          <Typography.Text>{RTInfo.remaining_runs}</Typography.Text>
        </Flex>
      </Flex>

      <Divider
        orientation="center"
        style={{ height: "auto" }}
        type="vertical"
      />

      <Flex flex={"0 0 45%"} vertical gap={token.margin}>
        <Typography.Text type="secondary">
          {t(translationTerms.recurringOpt.ends)}
        </Typography.Text>

        <Flex justify="space-between" flex={1}>
          {RTInfo.end_type === "runs_count" ? (
            <>
              <Typography.Text type="secondary">
                {t(translationTerms.recurringOpt.afterMaximumRuns)}
              </Typography.Text>
              <Typography.Text>{RTInfo.total_runs}</Typography.Text>
            </>
          ) : (
            <>
              <Typography.Text type="secondary">
                {t(translationTerms.recurringOpt.onCustomDate)}
              </Typography.Text>
              <Typography.Text>
                {RTInfo.end_date && dayjs(RTInfo.end_date).format("YYYY-MM-DD")}
              </Typography.Text>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
